import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Label } from '../components/Label'
import Image from '../components/Image'
import styletron from 'gatsby-plugin-styletron'
import { Palette, Spacing } from '../theme'

const ContactPage = () => {
    const css = styletron().css
    return (
        <Layout pageTitle='Contact'>
            <SEO title='Home' keywords={[`Bradley Sipp`, `Contact`, `Attorney`]} />

            <div
                className={css({
                    paddingLeft: Spacing.base,
                    paddingRight: Spacing.base,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    '@media screen and (max-width: 680px)': {
                        flexDirection: 'column',
                    },
                })}>
                <div className={css({ flex: 1, marginTop: Spacing.base })}>
                    <Label variant='title2'>Info</Label>
                    <Label>
                        217 South 9th Street <br />
                        Lincoln NE, 68508 <br />
                        USA <br />
                        p. 402.613.4006 <br />
                        e. bsipp@windstream.net
                    </Label>
                </div>
                <div className={css({ flex: 1, marginTop: Spacing.base, marginBottom: Spacing.large })}>
                    <Label variant='title2'>Office Hours</Label>
                    <Label
                        className={css({
                            width: '100%',
                        })}>
                        <span className={css(DayRule)}>Monday:</span> 8:30am to 5:00pm
                        <br />
                        <span className={css(DayRule)}>Tuesday:</span> 8:30am to 5:00pm
                        <br />
                        <span className={css(DayRule)}>Wednesday:</span> 8:30am to 5:00pm
                        <br />
                        <span className={css(DayRule)}>Thursday:</span> 8:30am to 5:00pm
                        <br />
                        <span className={css(DayRule)}>Friday:</span> 8:30am to 5:00pm
                        <br />
                        <span className={css(DayRule)}>Saturday:</span> By Appointment
                        <br />
                        <span className={css(DayRule)}>Sunday:</span> Closed
                        <br />
                    </Label>
                </div>
            </div>
        </Layout>
    )
}
export default ContactPage

const DayRule = {
    width: '100px',
    display: 'inline-block',
    fontWeight: 600,
}
